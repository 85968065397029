import toPairs from 'lodash/toPairs';
import { types as sdkTypes } from './sdkLoader';
import { diffInTime } from './dates';
import { countryCodes } from '../config/configCodes';

const { LatLng, Money } = sdkTypes;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 256;

const validationBlocker =
  'This message contains personal or sensitive information and we cannot let you send it. Please contact us at info@rentmyic.com';

const isNonEmptyString = val => {
  return typeof val === 'string' && val.trim().length > 0;
};

/**
 * Validator functions and helpers for Final Forms
 */

// Final Form expects and undefined value for a successful validation
const VALID = undefined;

export const required = message => value => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return message;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : message;
  }
  return VALID;
};

export const requiredStringNoTrim = message => value => {
  return typeof value === 'string' && value.length > 0 ? VALID : message;
};

// DEPRECATED in favor of required
export const requiredBoolean = message => value => {
  return typeof value === 'boolean' ? VALID : message;
};

// DEPRECATED in favor of required
export const requiredAndNonEmptyString = message => value => {
  return isNonEmptyString(value) ? VALID : message;
};

export const requiredFieldArrayCheckbox = message => value => {
  if (!value) {
    return message;
  }

  const entries = toPairs(value);
  const hasSelectedValues = entries.filter(e => !!e[1]).length > 0;
  return hasSelectedValues ? VALID : message;
};

export const requiredSelectTreeOption = message => value => {
  if (typeof value === 'undefined' || value === null || Object.values(value)?.length === 0) {
    return message;
  }
};

export const minLength = (message, minimumLength) => value => {
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length >= minimumLength ? VALID : message;
};

export const maxLength = (message, maximumLength) => value => {
  if (!value) {
    return VALID;
  }
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length <= maximumLength ? VALID : message;
};

export const nonEmptyArray = message => value => {
  return value && Array.isArray(value) && value.length > 0 ? VALID : message;
};

export const autocompleteSearchRequired = message => value => {
  return value && value.search ? VALID : message;
};

export const autocompletePlaceSelected = message => value => {
  const selectedPlaceIsValid =
    value &&
    value.selectedPlace &&
    value.selectedPlace.address &&
    value.selectedPlace.origin instanceof LatLng;
  return selectedPlaceIsValid ? VALID : message;
};

export const bookingDateRequired = inValidDateMessage => value => {
  const dateIsValid = value && value.date instanceof Date;
  return !dateIsValid ? inValidDateMessage : VALID;
};

export const bookingDatesRequired = (inValidStartDateMessage, inValidEndDateMessage) => value => {
  const startDateIsValid = value && value.startDate instanceof Date;
  const endDateIsValid = value && value.endDate instanceof Date;

  if (!startDateIsValid) {
    return inValidStartDateMessage;
  } else if (!endDateIsValid) {
    return inValidEndDateMessage;
  } else {
    return VALID;
  }
};

// Source: http://www.regular-expressions.info/email.html
// See the link above for an explanation of the tradeoffs.
const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emailFormatValid = message => value => {
  return value && EMAIL_RE.test(value) ? VALID : message;
};

export const moneySubUnitAmountAtLeast = (message, minValue) => value => {
  return value instanceof Money && value.amount >= minValue ? VALID : message;
};

const parseNum = str => {
  const num = Number.parseInt(str, 10);
  return Number.isNaN(num) ? null : num;
};

export const numberAtLeast = (message, minNumber) => value => {
  const valueNum = parseNum(value);
  return typeof valueNum === 'number' && valueNum >= minNumber ? VALID : message;
};

export const ageAtLeast = (message, minYears) => value => {
  const { year, month, day } = value;
  const dayNum = parseNum(day);
  const monthNum = parseNum(month);
  const yearNum = parseNum(year);

  // day, month, and year needs to be numbers
  if (dayNum !== null && monthNum !== null && yearNum !== null) {
    const now = new Date();
    const age = new Date(yearNum, monthNum - 1, dayNum);
    const ageInYears = diffInTime(now, age, 'years', true);

    return age && age instanceof Date && ageInYears >= minYears ? VALID : message;
  }
  return message;
};

export const validBusinessURL = message => value => {
  if (typeof value === 'undefined' || value === null) {
    return message;
  }

  const disallowedChars = /[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/;
  const protocolTokens = value.split(':');
  const includesProtocol = protocolTokens.length > 1;
  const usesHttpProtocol = includesProtocol && !!protocolTokens[0].match(/^(https?)/);

  const invalidCharacters = !!value.match(disallowedChars);
  const invalidProtocol = !(usesHttpProtocol || !includesProtocol);
  // Stripe checks against example.com
  const isExampleDotCom = !!value.match(/^(https?:\/\/example\.com|example\.com)/);
  const isLocalhost = !!value.match(/^(https?:\/\/localhost($|:|\/)|localhost($|:|\/))/);
  return invalidCharacters || invalidProtocol || isExampleDotCom || isLocalhost ? message : VALID;
};

export const validSsnLast4 = message => value => {
  return value.length === 4 ? VALID : message;
};

export const validHKID = message => value => {
  // Accept value 000000000 for testing Stripe
  if (value.length === 9 && value.match(/([0]{9})/)) {
    return VALID;
  }

  // HKID format example: AB364912(5)
  // ID can start with one or two letters and the check digit in the end can be in brackets or not
  if (value.length < 8) {
    return message;
  }

  // Handle possible brackets in value
  if (value.charAt(value.length - 3) === '(' && value.charAt(value.length - 1) === ')') {
    value = value.substring(0, value.length - 3) + value.charAt(value.length - 2);
  }
  value = value.toUpperCase();

  // Check that pattern is correct and split value to array
  const hkidPattern = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
  const matchArray = value.match(hkidPattern);

  if (!matchArray) {
    return message;
  }

  const charPart = matchArray[1];
  const numPart = matchArray[2];
  const checkDigit = matchArray[3];

  // Calculate the checksum for character part.
  // Transfer letters to numbers so that A=10, B=11, C=12 etc.
  // If there is only one letter in the ID use 36 as the first value
  // Total calculation is weighted so that 1st digit is x9, 2nd digit x8, 3rd digit x7 etc.

  const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let checkSum = 0;

  if (charPart.length === 2) {
    checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
    checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
  } else {
    checkSum += 9 * 36;
    checkSum += 8 * (10 + strValidChars.indexOf(charPart));
  }

  // Calculate the checksum for numeric part

  for (let i = 0, j = 7; i < numPart.length; i++, j--) {
    checkSum += j * numPart.charAt(i);
  }

  // Verify the check digit
  const remaining = checkSum % 11;
  let verify = remaining === 0 ? 0 : 11 - remaining;
  verify = verify.toString();
  const isValid = verify === checkDigit || (verify === 10 && checkDigit === 'A');

  return isValid ? VALID : message;
};

export const validSGID = message => value => {
  return value.length === 9 ? VALID : message;
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), VALID);
const emailPattern = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

const phonePattern = /(\+?\d{1,4})?(\s|-|\()?(\d{3})(\s|-|\))?(\d{3})(\s|-)?(\d{4,})/g;

const majorEmailProviders = [
  '@',
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'icloud.com',
  'aol.com',
  'protonmail.com',
  'zoho.com',
  'mail.com',
  'gmail',
  'yahoo',
  'outlook',
  'hotmail',
  'icloud',
  'aol',
  'protonmail',
  'zoho',
  'mail',
];

const socialHandles = [
  'facebook.com',
  'twitter.com',
  'instagram.com',
  'linkedin.com',
  'tiktok.com',
  'snapchat.com',
  'pinterest.com',
  'slack.com',
  'reddit.com',
  'facebook',
  'twitter',
  'instagram',
  'linkedin',
  'tiktok',
  'snapchat',
  'pinterest',
  'reddit',
  'fb',
  'insta',
  'slack',
];

const numberWords = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
};

const ukPostalCodePattern = /(GIR\s?0AA|[A-PR-UWYZ][A-HK-Y]?[0-9][0-9A-HJKS-UW]? ?[0-9][ABD-HJLNP-UW-Z]{2})/i;

const ukAddressKeywords = [
  'abbey',
  'arcade',
  'avenue',
  'bank',
  'barton',
  'beach',
  'bend',
  'bourne',
  'bottom',
  'broadway',
  'brook',
  'bypass',
  'camp',
  'canal',
  'causeway',
  'chase',
  'circle',
  'circus',
  'cliff',
  'close',
  'common',
  'copse',
  'corner',
  'court',
  'crescent',
  'croft',
  'cross',
  'crossing',
  'cutting',
  'dale',
  'dene',
  'drive',
  'end',
  'esplanade',
  'estate',
  'field',
  'fold',
  'gardens',
  'gate',
  'glade',
  'glebe',
  'glen',
  'green',
  'grange',
  'grove',
  'heath',
  'hill',
  'hollow',
  'kingsway',
  'knoll',
  'lane',
  'lea',
  'leigh',
  'mews',
  'mount',
  'orchard',
  'park',
  'parade',
  'passage',
  'paddock',
  'path',
  'place',
  'plantation',
  'plaza',
  'pond',
  'quay',
  'rest',
  'retreat',
  'ride',
  'rise',
  'road',
  'row',
  'square',
  'spring',
  'stables',
  'street',
  'terrace',
  'track',
  'vale',
  'view',
  'village',
  'walk',
  'way',
  'wharf',
  'wood',
  'yard',
  'wynd',
  'brae',
  'haugh',
  'loan',
  'rowan',
  'gait',
  'rigg',
  'gate',
  'pass',
  'trail',
  'brink',
  'ridge',
  'spur',
  'valley',
  'walks',
  'reach',
  'roundabout',
  'turn',
  'branch',
  'close',
  'mead',
  'beach',
  'marsh',
  'nook',
  'outlook',
  'climb',
  'top',
  'gap',
  'falls',
  'craig',
  'point',
  'glen',
  'lea',
  'side',
  'burn',
  'vista',
  'towers',
  'gardens',
  'garden',
  'toll',
  'view',
  'meadow',
  'grange',
  'haven',
  'bridge',
  'tarn',
  'crags',
  'court',
  'clearing',
  'courtyard',
  'drift',
  'drive',
  'ferry',
  'fleet',
  'fold',
  'ford',
  'grounds',
  'hedge',
  'heights',
  'hilltop',
  'island',
  'landing',
  'lawn',
  'lodge',
  'lookout',
  'manor',
  'manse',
  'market',
  'meadow',
  'moor',
  'mound',
  'outlook',
  'park',
  'parklands',
  'pass',
  'passage',
  'peak',
  'pike',
  'place',
  'plantation',
  'plaza',
  'pond',
  'quarry',
  'quay',
  'rest',
  'retreat',
  'ridge',
  'rise',
  'river',
  'road',
  'row',
  'sands',
  'seashore',
  'slopes',
  'spur',
  'stream',
  'summit',
  'tarn',
  'towers',
  'track',
  'vale',
  'valley',
  'villas',
  'view',
  'walk',
  'water',
  'way',
  'wharf',
  'wood',
  'yard',
  'crescent',
  'terrace',
  'avenue',
  'close',
  'grange',
  'court',
  'place',
  'drive',
  'square',
  'lane',
  'park',
  'gardens',
  'street',
  'meadow',
  'rise',
  'hill',
  'vale',
  'walk',
  'green',
  'grove',
  'wood',
  'field',
  'croft',
  'fold',
  'mead',
  'ridge',
  'bend',
  'mount',
  'heath',
  'view',
  'nook',
  'glade',
  'glen',
  'end',
  'way',
  'lea',
  'brook',
  'dell',
  'gate',
  'mews',
  'bank',
  'spring',
  'chase',
  'row',
  'ridge',
  'gap',
  'woodlands',
  'orchard',
  'pasture',
  'spinney',
  'turn',
  'barn',
  'bluff',
  'hillcrest',
  'loch',
  'moorland',
  'overlook',
  'parade',
  'parkway',
  'pass',
  'quarry',
  'range',
  'riverside',
  'seaway',
  'slope',
  'terraces',
  'top',
  'vista',
  'bay',
  'brae',
  'dale',
  'dene',
  'dean',
  'dyke',
  'hollow',
  'hurst',
  'knoll',
  'level',
  'mead',
  'march',
  'shaw',
  'tor',
  'walks',
  'well',
  'woodside',
  'bank',
  'bluff',
  'bottom',
  'bourne',
  'brink',
  'burn',
  'clearing',
  'clough',
  'cliff',
  'cut',
  'cutting',
  'dingle',
  'dip',
  'downs',
  'drove',
  'dyke',
  'edge',
  'fen',
  'ferry',
  'firth',
  'flood',
  'furlong',
  'garth',
  'glen',
  'green',
  'grove',
  'hall',
  'heights',
  'highlands',
  'holm',
  'hope',
  'hurst',
  'island',
  'lea',
  'lea',
  'loch',
  'lough',
  'low',
  'manor',
  'manse',
  'marsh',
  'meadow',
  'moor',
  'mound',
  'overlook',
  'pass',
  'path',
  'plains',
  'reach',
  'rest',
  'retreat',
  'riding',
  'rigg',
  'rising',
  'roost',
  'rough',
  'run',
  'scarp',
  'shaw',
  'shelf',
  'side',
  'skerry',
  'slade',
  'stile',
  'strand',
  'strath',
  'stream',
  'summit',
  'swale',
  'swamp',
  'swath',
  'terrace',
  'thicket',
  'thorn',
  'tor',
  'tower',
  'track',
  'vale',
  'valley',
  'vean',
  'wash',
  'water',
  'waterside',
  'well',
  'whin',
  'wood',
  'yard',
  'croft',
  'croft',
  'ridge',
  'quay',
  'quarry',
  'side',
  'sand',
  'stile',
  'top',
  'fold',
  'park',
  'valley',
  'ridge',
  'rising',
  'terrace',
  'orchard',
  'villas',
  'park',
  'place',
  'square',
  'market',
  'gate',
  'dell',
  'grove',
  'corner',
  'wood',
  'manor',
  'meadows',
  'street',
  'lane',
  'road',
  'paddock',
  'plantation',
  'arcade',
  'ridge',
  'walk',
  'square',
  'hollow',
  'hill',
  'borough',
  'side',
  'knoll',
  'meadow',
  'green',
  'field',
  'park',
  'court',
  'stables',
  'hill',
  'bottom',
  'wood',
  'bridge',
  'plaza',
  'way',
  'march',
  'paddock',
  'lane',
  'crest',
  'lane',
  'close',
  'grange',
  'grove',
  'wood',
  'mead',
  'ridge',
  'field',
  'grove',
  'lane',
  'park',
  'meadow',
  'ridge',
  'vale',
  'walk',
  'field',
  'green',
  'square',
  'hill',
  'croft',
  'lawn',
  'mead',
  'hill',
  'street',
  'wood',
  'orchard',
  'vale',
  'terrace',
  'park',
  'hill',
  'manor',
  'park',
  'square',
  'hollow',
  'hill',
  'road',
  'court',
  'cross',
  'crossing',
  'drive',
  'ford',
  'ridge',
  'hill',
  'vale',
  'manor',
  'orchard',
  'avenue',
  'beach',
  'close',
  'court',
  'dell',
  'drive',
  'grove',
  'lane',
  'mead',
  'meadow',
  'rise',
  'valley',
  'walk',
  'wood',
  'court',
  'drive',
  'green',
  'grove',
  'lane',
  'lawn',
  'mead',
  'meadow',
  'plantation',
  'road',
  'vale',
  'villas',
  'walk',
  'wood',
  'woodlands',
  'arcade',
  'avenue',
  'beach',
  'bottom',
  'chase',
  'close',
  'court',
  'croft',
  'cut',
  'dene',
  'drive',
  'end',
  'field',
  'fold',
  'glade',
  'glebe',
  'glen',
  'grove',
  'hill',
  'hurst',
  'lawn',
  'lea',
  'leigh',
  'mews',
  'park',
  'place',
  'plantation',
  'rise',
  'road',
  'row',
  'square',
  'street',
  'terrace',
  'view',
  'walk',
  'way',
  'wood',
  'yard',
  'villas',
  'park',
  'gardens',
  'hill',
  'park',
  'close',
  'croft',
  'fold',
  'woodland',
  'heath',
  'orchard',
  'grove',
  'place',
  'court',
  'row',
  'rowan',
  'wood',
  'end',
  'rise',
  'hill',
  'manor',
  'farm',
  'pastures',
  'bridge',
  'dell',
  'point',
  'ridge',
  'grove',
  'drive',
  'view',
  'valley',
  'walk',
  'hill',
  'meadow',
  'croft',
  'woodlands',
  'mews',
  'way',
  'orchard',
  'lane',
  'green',
  'heath',
  'hill',
  'wood',
  'orchard',
  'way',
  'lane',
  'street',
  'bank',
  'walk',
  'drive',
  'grove',
  'hill',
  'valley',
  'avenue',
  'close',
  'mead',
  'woodland',
  'hill',
  'bank',
  'beach',
  'bridge',
  'brook',
  'burn',
  'close',
  'copse',
  'corner',
  'crescent',
  'crook',
  'dale',
  'dene',
  'drive',
  'end',
  'ford',
  'field',
  'green',
  'grove',
  'lane',
  'lawns',
  'mead',
  'meadow',
  'park',
  'plaza',
  'ridge',
  'road',
  'row',
  'terrace',
  'valley',
  'view',
  'walk',
  'wood',
  'yard',
  'brae',
  'copse',
  'down',
  'end',
  'field',
  'garth',
  'glade',
  'green',
  'grove',
  'hill',
  'hollow',
  'hurst',
  'lane',
  'lea',
  'leigh',
  'mews',
  'mound',
  'orchard',
  'park',
  'pasture',
  'ridge',
  'rise',
  'road',
  'square',
  'street',
  'terrace',
  'vale',
  'villas',
  'walk',
  'way',
  'wood',
  'arcade',
  'avenue',
  'bank',
  'barton',
  'bay',
  'bend',
  'bottom',
  'brae',
  'braes',
  'brink',
  'brook',
  'burn',
  'by-pass',
  'camp',
  'clearing',
  'close',
  'common',
  'corner',
  'crescent',
  'croft',
  'cross',
  'cutting',
  'dale',
  'dene',
  'dene',
  'drive',
  'end',
  'esplanade',
  'field',
  'fold',
  'ford',
  'gardens',
  'gate',
  'glade',
  'glebe',
  'glen',
  'green',
  'grange',
  'grove',
  'heath',
  'hill',
  'hollow',
  'hurst',
  'knoll',
  'knoll',
  'lane',
  'lea',
  'leigh',
  'mews',
  'mount',
  'orchard',
  'park',
  'parade',
  'passage',
  'paddock',
  'path',
  'place',
  'plaza',
  'pond',
  'quay',
  'rest',
  'retreat',
  'ridge',
  'rise',
  'road',
  'row',
  'square',
  'spring',
  'stables',
  'street',
  'terrace',
  'track',
  'vale',
  'view',
  'walk',
  'way',
  'wharf',
  'wood',
  'yard',
  'abbey',
  'arcade',
  'avenue',
  'bank',
  'barton',
  'beach',
  'bend',
  'bourne',
  'bottom',
  'broadway',
  'brook',
  'bypass',
  'camp',
  'canal',
  'causeway',
  'chase',
  'circle',
  'circus',
  'cliff',
  'close',
  'common',
  'copse',
  'corner',
  'court',
  'crescent',
  'croft',
  'cross',
  'crossing',
  'cutting',
  'dale',
  'dene',
  'drive',
  'end',
  'esplanade',
  'estate',
  'field',
  'fold',
  'gardens',
  'gate',
  'glade',
  'glebe',
  'glen',
  'green',
  'grange',
  'grove',
  'heath',
  'hill',
  'hollow',
  'kingsway',
  'knoll',
  'lane',
  'lea',
  'leigh',
  'mews',
  'mount',
  'orchard',
  'park',
  'parade',
  'passage',
  'paddock',
  'path',
  'place',
  'plantation',
  'plaza',
  'pond',
  'quay',
  'rest',
  'retreat',
  'ride',
  'rise',
  'road',
  'row',
  'square',
  'spring',
  'stables',
  'street',
  'terrace',
  'track',
  'vale',
  'view',
  'village',
  'walk',
  'way',
  'wharf',
  'wood',
  'yard',
  'wynd',
  'brae',
  'wynd',
  'haugh',
  'loan',
  'rowan',
  'gait',
  'rigg',
  'gate',
  'pass',
  'trail',
  'brink',
  'ridge',
  'spur',
  'valley',
  'walks',
];

const containsEmail = description => emailPattern.test(description);

// Updated phone number check using the dial codes from countryCodes
const containsPhone = (description) => {

  const phoneMatch = description.match(phonePattern);
  if (phoneMatch) {
    const phoneNumber = phoneMatch[0] && phoneMatch[0].replace(/\D/g, '');

    // Block numbers that start with "078"
    if (phoneNumber.startsWith("078")) {
      return true; // Block the number if it starts with "078"
    }

    // Check if the phone number contains any dial code from the countryCodes array
    const hasCountryCode = countryCodes.some((country) => {
      const dialCode = country?.dial_code;
      return phoneNumber?.startsWith(dialCode) || phoneNumber?.startsWith(`+${dialCode}`);
    });

    return hasCountryCode;
  }
  return false;
};


const containsObfuscatedNumber = description => {
  const lowerDescription = description.toLowerCase();

  const extractedNumbers = Object.keys(numberWords).reduce((acc, word) => {
    const regex = new RegExp(word, 'g');
    const matches = lowerDescription.match(regex);
    if (matches) {
      acc.push(...matches.map(() => numberWords[word]));
    }
    return acc;
  }, []);

  return extractedNumbers.length >= 5; // Adjust the threshold as needed
};

const containsMajorEmailProvider = description => {
  return majorEmailProviders.some(provider => description.includes(provider));
};

const containsSocialHandle = description => {
  return socialHandles.some(handle => description.includes(handle));
};

const containsAtSymbol = description => description.includes('@');

const containsUkAddress = description => {
  const lowerDescription = description.toLowerCase();

  // Check for postal codes
  const hasUkPostalCode = ukPostalCodePattern.test(description);

  // Check for address keywords
  const hasUkAddressKeywords = ukAddressKeywords.some(keyword =>
    lowerDescription.includes(keyword)
  );

  return hasUkPostalCode || hasUkAddressKeywords;
};

export const validateInputField = () => value => {
  if (!value) {
    return null;
  }

  if (
    containsEmail(value) ||
    containsPhone(value) || // This will now only block if the phone number contains a recognized country code
    containsObfuscatedNumber(value) ||
    containsMajorEmailProvider(value) ||
    containsSocialHandle(value) ||
    containsAtSymbol(value) 
    // containsUkAddress(value) // New check for UK addresses
  ) {
    return validationBlocker;
  }

  return null;
};
